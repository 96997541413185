<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                hover
                ref="table"
                :items="orders"
                :fields="fields"
                responsive="sm"
                :empty-text="'There are no records to show'"
                :empty-filtered-text="'There are no records matching your request'"
                selectable
                :select-mode="'single'"
                @row-selected="showProducts"
              >
                <!-- A virtual column -->
                <template v-slot:cell(No)="data">
                  {{ (currentPage - 1) * perPage + (data.index + 1) }}
                </template>
                <!-- date -->
                <template v-slot:cell(date)="data">
                  {{ data.item.date | date_time }}
                </template>
                <!-- date -->
                <template v-slot:cell(quantity)="data">
                  {{ data.item.quantity }} Items
                </template>
                <!-- total -->
                <template v-slot:cell(total)="data">
                  Rp. {{ data.item.total | convertToRupiah }}
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>      

    <!-- modal -->
    <b-modal
      hide-footer
      size="lg"
      id="modal-detail"
      centered
      title="Detail Products"
      title-class="font-18" 
      no-close-on-backdrop
      >
      <div class="d-inline-block w-100 pad-10">
        <template v-for="(product, index) in products">
          <div class="row mb-3 pb-2" :key="index" style="border-bottom: 1px solid;">
            <div class="col-md-4">
              <b-img thumbnail fluid rounded :src="product.thumbnail" alt="Image 1"></b-img>
            </div>
            <div class="col-md-8">
              <table class="table">
                <tr>
                  <th style="width: 10%">Name</th>
                  <td>: {{ product.name }}</td>
                </tr>
                <tr>
                  <th>Price</th>
                  <td>: Rp. {{ product.price | convertToRupiah}}</td>
                </tr>
                <tr>
                  <th>Quantity</th>
                  <td>: {{ product.quantity }}</td>
                </tr>
                <tr>
                  <th>Total</th>
                  <td>: {{ product.total | convertToRupiah }}</td>
                </tr>
              </table>
            </div>
          </div> 
        </template>
      </div>
    </b-modal>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

/**
 * Advanced table component
 */
export default {
  page: {
    title: "History Purchase",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          href: "/home"
        },
        {
          text: "User",
          href: "/user"
        },
        {
          text: "History Purchase",
          active: true
        }
      ],
      user: {
        firstname: null,
        lastname: null,
        phone: null,
        email: null,
      },
      orders: [],
      fields: [
        'No',
        { key: "status", label: "Status", sortable: true },
        { key: "date", label: "Date", sortable: true }, 
        { key: "quantity", label: "Quantity", sortable: true}, 
        { key: "total", label: "Total", sortable: true }
      ],
      products: [],
      isError: false,
      errorMessage: null
    };
  },
  computed: {
    title() {
      return `${this.user.firstname} ${this.user.lastname} History Purchase Products`
    }
  },
  mounted() {
    this.fetchUser()
    this.fetchData()
  },
  methods: {
    fetchUser() {
      this.$axios.get(`/user/${this.$route.params.id}`)
        .then( (response) => { 
          const result = response.data.result

          let phoneNumber = null
          if (result.user.phone.toString().substring(0,3) == "+62") {
            phoneNumber = result.user.phone.toString()
          } else if(result.user.phone.toString().substring(0,2) == "62"){ 
            phoneNumber = `+${result.user.phone.toString()}`
          } else if(result.user.phone.toString().charAt(0) == "0"){ 
            phoneNumber = `+62${result.user.phone.toString().substring(1)}`
          } else {
            phoneNumber = `${result.user.phone.toString()}`
          }

          this.user = {
            firstname: result.user.firstname.charAt(0).toUpperCase() + result.user.firstname.slice(1),
            lastname: result.user.lastname.charAt(0).toUpperCase() + result.user.lastname.slice(1),
            phone: phoneNumber.substring(3),
            email: result.user.email,
          }

        }).catch(e => {
          if (e.response) {  
            this.isError = true
            this.errorMessage = e.response.data.message
          }
        })
    },
    fetchData() { 
      this.$axios.get(`checkout-product/history/${this.$route.params.id}`)
        .then( response => {
          const data = response.data.result.orders
          this.orders = data.map(order => ({
            ...order,
            quantity: order.products.reduce((total, item) => { return total + item.quantity }, 0)
          }))
        })
    },
    showProducts(items) {
      this.products = items[0].products
      this.$bvModal.show('modal-detail')
    }
  }
};
</script>